import BaseService from "./BaseService";
import http from './HttpService'
import { API_BASE_URL_ENV } from "../common/common";

const apiEndpoint = API_BASE_URL_ENV() + "/quotation";

export default class QuotationService extends BaseService {
    constructor() {
        super(apiEndpoint);
    }

    responseQuotation(data){
        return http.post(`${apiEndpoint}/response`, data)
    }
}
