import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AboutService from '../../services/AboutService';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import CilentsService from '../../services/CilentsService';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation } from 'swiper/modules';
import './style.css'

const AboutUs = () => {
  const {t} = useTranslation()
  const [about, setAbout] = useState([])
  const [clients, setClients] = useState([])
  const types = [
    {label: "Our History", value: "about"},
    {label: "Our Values", value: "values"},
    {label: "Our Clients", value: "clients"},
    {label: "Company Profile", value: "company"},
  ]
  const [tab, setTab] = useState("")
  const lang = useSelector(state => state?.lang?.lang)
  const aboutService = new AboutService()
  const cilentsService = new CilentsService()
  const location = useLocation()

  const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        // cssEase: "linear",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            }
          ]
    };
  
  useEffect(()=>{
    if(location?.pathname === "/about-us" && !tab){
      setTab(types[0])
    }
  } ,[location])

  useEffect(()=>{
    if(tab?.value === 'clients'){
      cilentsService?.getList().then(res=> {
        if(res?.status){
          setClients(res?.data?.data)
        }
      })
      return
    }
    let params = {
      type: "about" 
    }
    if(location?.pathname === "/about-us"){
      if(tab?.value === 'about') params['type'] = 'about'
      if(tab?.value === 'values') params['type'] = 'values'
      if(tab?.value === 'company') params['type'] = 'company'
    } else if (location?.pathname === "/privacy"){
      params['type'] = 'Privacy'
    } else if (location?.pathname === "/terms"){
      params['type'] = 'faqs'
    }
  
    aboutService?.getList(params).then(res=>{
      if(res?.status === 200){
        setAbout(res?.data?.data)
      }
    })
  }, [location, tab])

  return (<div className='aboutUs'>
        <h5 className='type-title'>
          <span>{location?.pathname === "/about-us" ? t("about-us") : location?.pathname === "/privacy" ? t('privacy') : t('terms and conditions')}</span>
        </h5>
      <Row>
        {location?.pathname === "/about-us" && <Col md={2} className='px-3 aboutUs-tabs'>
          <div className='aboutUs-tab'>
            {types?.map((type, index) => {
              return <p 
                className={`tab ${type?.label === tab?.label ? 'active' : ''}`}
                key={index}
                onClick={()=> setTab(type)}
              >{t(type?.label)}</p>
            })}
          </div>
        </Col>}
        <Col md={location?.pathname === "/about-us" ? 10 : 12}>
          {tab?.value !== 'clients' && about?.map((info,index)=>{
              return <div className="about-us" key={index}>
                  <div className='w-50 description'>
                    <h2 className='mb-4'>{lang === 'en' ? info?.title_en : info?.title_ar}</h2>
                    <p dangerouslySetInnerHTML={{ __html: lang === 'en' ? info?.description_en : info?.description_ar }}></p>
                </div>
                <div className='w-50 image'>
                  <img src={info?.image} className='w-100' alt='about' />
                </div>
              </div>
          })}
          {tab?.value === 'clients' && <div className='clients'>
            {/* <Row className='p-3'>
              {clients?.map(res => {
                return <Col md={3} key={res?.id} className={`p-3 ${clients?.length > 4 ? 'mb-3' : ''}`}>
                  <img src={res?.image} alt='client' className='w-100' style={{ minHeight: '100%'}} />
                </Col>
              })}
            </Row> */}
            <Swiper
              slidesPerView={2.25}
              spaceBetween={15}
              pagination={{
                clickable: false,
              }}
              breakpoints={{
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
              }}
              modules={[Mousewheel, Navigation]}
              className="mySwiper"
            >
              {clients?.map((imgs, index)=>{
                return <SwiperSlide key={index}>
                  <img 
                    src={imgs.image}
                    height='120px' 
                    width='120px' 
                    alt='client' 
                  />
                </SwiperSlide>
              })}
            </Swiper>
          </div>}
        </Col>
      </Row>
    </div>
  );
};

export default AboutUs;
