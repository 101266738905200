export const en = {
    "customer": "Customer", 
    "whole_sale": "Whole Sale",
    "home": "Home",
    "products": "Products",
    "categories": "Categories",
    "about-us": "About Us",
    "quick_links": "Quick Links",
    "previous": "Previous",
    "Brand": "Brand",
    "Sort by": "Sort by",
    "Price": "Price",
    "You Selected": "You Selected",
    "Items": "Items",
    "Low to High": "Low to High",
    "High to Low": "High to Low",
    "A-Z": "A-Z",
    "Z-A": "Z-A",
    "Our History": "Our History", 
    "Our Values": "Our Values", 
    "Our Clients": "Our Clients", 
    "Hi": "Hi",
    "Date": "Date",
    "Your quotation is ready, Please download.": "Your quotation is ready, Please download.",
    "Register Successfully.": "Register Successfully.",
    "Login Successfully.": "Login Successfully.",
    "Profile Image Updated Successfully.": "Profile Image Updated Successfully.",
    "Profile Data Updated Successfully.": "Profile Data Updated Successfully.",
    "Logout Successfully.": "Logout Successfully.",
    "Company Profile": "Company Profile",
    "Company Name": "Company Name",
    "Civil ID": "Civil ID",
    "Upload your ID": "Upload your ID",
    "Upload your Licence": "Upload your Licence",
    "Upload File": "Upload File",
    "Company Licence": "Company Licence",
    "Authorized Signatory": "Authorized Signatory",
    "Information": "Information",
    "Description": "Description",
    "Dynamic Variant": "Dynamic Variant",
    "Similar Products": "Similar Products",
    "next": "Next",
    "results": "Results",
    "Shop By Category": "Shop By Category",
    "View All": "View All",
    "Explore All": "Explore All",
    'Featured Products': 'Featured Products', 
    "Working Hours": "Working Hours",
    "Working Days": "Working Days",
    "Best seller": "Best seller", 
    "Show Rooms": "Show Rooms",
    "Quotation Send Successfully": "Quotation Send Successfully.",
    'Offers': 'Offers', 
    'branches': 'Branches', 
    'brands': 'Brands', 
    "Contacts": "Contacts",
    "Follow us": "Follow us",
    "page not found!": "Page Not Found!",
    'New Arrivals': 'New Arrivals',
    "There's no Products": "There's no Products",
    "Explore": "Explore",
    "No. Code": "No. Code",
    "All": "All",
    "QTY": "QTY",
    "Start From": "Start From",
    "Send Quotation": "Send Quotation",
    "Feature Products": "Feature Products",
    "Best Selling Products": "Best Selling Products",
    "Style Up Your Looks": "Style Up Your Looks",
    "All Rights": "All Rights reserved",
    "Hasan Al Naser": "Hasan Al Naser",
    "Save": "Save",
    "Powered By": "Powered By",
    "Total Price": "Total Price",
    "Continue": "Continue",
    "more Details": "More Details",
    "Your Cart is Empty": "Your Cart is Empty",
    "KWD": "KWD",
    "Cash in Delivery": "Cash in Delivery",
    "ordered successfully": "Your order has been Submitted Successfully",
    "ordered successfully description": "Your order has been submitted successfully",
    "ordered failed": "Something went wrong!",
    "ordered failed description": "The payment not proceed",
    "Cancel": "Cancel",
    "Cancel Message": "Are you sure you want to cancel this order? This action cannot be undone, and any progress related to the order will be lost.",
    "try again": "Try Again",
    "out of stock": "Out of Stock",
    "Download": "Download",
    "You have to register as wholesale to in order to proceed": "You have to register as wholesale to in order to proceed",
    "Style Up": "Style Up",
    "Add To Cart": "Add To Cart",
    "Cart": "Cart",
    "contact": "Contact",
    "Terms": "Terms",
    "Our Policy": "Our Policy",
    "Terms & Conditions": "Terms & Conditions",
    "Name": "Name",
    "Email": "Email",
    "Phone": "Phone",
    "Message": "Message",
    "Wholesale": "Wholesale",
    "Filter": "Filter",
    "You can select multiple items and request an inquiry for all of them.": "You can select multiple items and request an inquiry for all of them.",
    "CONTACT US ON WHATSAPP": "CONTACT US ON WHATSAPP",
    "GET IN TOUCH": "GET IN TOUCH",
    "Product": "Product",
    "Price": "Price",
    "Quantity": "Quantity",
    "Total": "Total",
    "Sub Total": "Sub Total",
    "Payment Details": "Payment Details",
    "Proceed": "Do You Want To Proceed To Checkout As",
    "Login": "Login",
    "Continue as a Guest": "Continue as a Guest",
    "Login Into Your Account": "Login Into Your Account",
    "Create New Account": "Create New Account",
    "Password": "Password",
    "Don't Have Account": "Don't Have Account",
    "Sign Up": "Sign Up",
    "Have Account": "Have Account",
    "Checkout": "Checkout",
    "User Details": "User Details",
    "Full Name": "Full Name",
    "Address Details": "Address Details",
    "Area": "Area",
    "Block": "Block",
    "Street": "Street",
    "Avenue": "Avenue",
    "Pending": "Pending",
    "pending": "Pending",
    "Reference ID": "Reference ID",
    "Rejected": "Rejected",
    "Floor Number": "Floor Number",
    "Optional": "Optional",
    "Other Instructions": "Other Instructions",
    "Address Type": "Address Type",
    "Building Number": "Building Number",
    "Building": "Building",
    "Floor": "Floor",
    "Apartment Number": "Apartment Number",
    "House Number": "House Number",
    "House": "House",
    "Flat": "Flat",
    "Office": "Office",
    "Office Number": "Office Number",
    "Extra Directions": "Extra Directions",
    "Please Enter": "Please Enter",
    "Please Select": "Please Select",
    "Coupon Code": "Coupon Code",
    "Apply": "Apply",
    "Payment Method": "Payment Method",
    "Payment": "Payment",
    "KNET": "KNET",
    "Visa/MasterCard": "Visa/MasterCard",
    "Payment Detail": "Payment Detail",
    "Discount": "Discount",
    "Delivery Charges": "Delivery Charges",
    "Submit Order": "Submit Order",
    "Submit": "Submit",
    "show some love to your bag": "Show Some Love to your Bag",
    "Fill your bag with things that make you happy": "Fill your bag with things that make you happy",
    "Profile": "Profile",
    "Logout": "Logout",
    "My Account": "My Account",
    "My Orders": "My Orders",
    "Update Account": "Update Account",
    "Update": "Update",
    "Change Password": "Change Password",
    "New Password": "New Password",
    "privacy": "Privacy",
    "terms and conditions": "Terms and Conditions",
    "Old Password": "Old Password",
    "Confirm New Password": "Confirm New Password",
    "This Field is required": "This Field is required",
    "Amount": "Amount",
    "Search by Product": "Search by Product",
    "Add": "Add",
    "Add Address First": "Add Address First",
    "Buy it again": "Buy it again",
    "There are No Products": "There are No Products",
    "Product Added To Cart": "Product Added To Cart",
    "Forget Password?": "Forget Password?",
    "Default": "Default",
    "Forget Password": "Forget Password",
    "Back": "Back",
    "Delete": "Delete",
    "Address Added Successfully.": "Address Added Successfully.",
    "Address Updated Successfully.": "Address Updated Successfully.",
    "Address Deleted Successfully.": "Address Deleted Successfully.",
    "Message Sent Successfully.": "Message Sent Successfully.",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "Message Required": "Message Required",
    "promo code is invalid!": "Promo Code is Invalid!",
    "Successfully Applied": "Successfully Applied",
    "Remove from Cart": "Remove from Cart",
    "Remove": "Remove",
    "Search": "Search",
    "Classic kitchen": "Classic Kitchen",
    "Modern kitchen": "Modern Kitchen",
    "No Address": "No Address",
    "Add New Address": "Add New Address",
    "Address Name": "Address Name",
    "Addresses": "Addresses",
    "Set Default": "Set Default",
    "Edit": "Edit",
    "See More": "See More",
    "Wishlist": "Wishlist",
    "My Quotation": "My Quotation",
    "Quotation": "Quotation",
    "Quotation Details": "Quotation Details",
    "Approve & Add to Cart": "Approve & Add to Cart",
    "Reject": "Reject",
    "Reason": "Reason",
    "Quotation Rejection": "Quotation Rejection",
    "optional": "optional",
    "Confirm": "Confirm",
    "new": "New",
    "accepted": "Accepted",
    "rejected": "Rejected",
    "Quotation Rejection sent successfully": "Quotation Rejection sent successfully",
    "Quotation is Pending": "Quotation is Pending",
    "Quotation is Accepted": "Quotation is Accepted",
    "Special Offers": "Special Offers",
}