
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { loadingToggleAction, signupAction } from "../../../store/actions/AuthActions"
import { useLocation, useNavigate } from "react-router-dom"
import CartService from "../../../services/CartService"
import BaseService from "../../../services/BaseService"

const Signup = ({setType, setModal}) => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        username: "",
        phone: "",
        civil_id: "",
        company_license: "",
        authorized_signatory: "",
        company_name: ""
    })
    const tabs = ["customer", "whole_sale"]
    const [showPassword, setShowPassword] = useState(false)
    const [civil, setCivil] = useState('Upload your ID')
    const [licence, setLicence] = useState('Upload your Licence')
    const [signatory, setSignatory] = useState('Upload File')
    const [loading, setLoading] = useState(false)
    const [selectedTab, setSelectedTab] = useState('customer')
    const lang = useSelector(state=> state?.lang?.lang)
    const {t} = useTranslation()
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cartService = new CartService()
    const user = useSelector(state => state?.user)

    const submit = () => {
        let cartProducts = {}
        if(selectedTab === 'customer'){
            cartProducts = {
                products: user?.cart?.map(product => {
                    return {
                        dynamic_variant: product?.dynamicVariants?.filter(res=> res?.amount > 0)?.map(res=>{
                            return {
                                dynamic_variant_id: res?.id,
                                amount: res?.amount
                            }
                        }),
                        amount: product?.amount,
                        product_id: product?.id
                    }
                })
            }
            if(user?.promoCode) cartProducts['promoCode'] = user?.promoCode?.coupon
        }
        let pathname = location?.pathname
        let data = {
            phone: formData.phone,
            email: formData.email,
            password: formData.password,
            username: formData.username,
            type: selectedTab === 'customer' ? 'user' : "whole_sale"
        }
        if(selectedTab !== 'customer'){
            data['company_name'] = formData.company_name;
            data['civil_id'] = formData?.civil_id;
            data['company_license'] = formData?.company_license;
            data['authorized_signatory'] = formData?.authorized_signatory;
        }
        dispatch(loadingToggleAction(true));
        dispatch(signupAction(data, navigate, pathname, setModal, setLoading, cartService, cartProducts));
    }

    const fileHandler = (e, name) => {
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);

        new BaseService().postUpload(filesData[0]).then((res) => {
            if (res?.data?.status) {
                setFormData({ ...formData, [name]: res.data.url });
            }
        });
    };

    return <AvForm
    className={`form-horizontal signup-form ${lang}`}
    style={{direction: lang === 'ar' ? 'rtl' : 'ltr'}}
    onValidSubmit={submit}>
        <Row>
            <Col md={12}>
                <h4 className="title">{t("Create New Account")}</h4>
            </Col>
            <Col md={12}>
                <div className="tabs">
                {tabs?.map((tab, index) => {
                    return <p key={index} onClick={()=> setSelectedTab(tab)} className={`tab ${tab === selectedTab ? 'active' : ''}`}>{t(tab)}</p>
                })}
                </div>
            </Col>
            {/* Name / Company Name */}
            <Col md={12}>
                <AvField
                    label={t("Name")}
                    type='text'
                    placeholder={t("Name")}
                    bsSize="lg"
                    name='username'
                    validate={{
                        required: {
                            value: true,
                            errorMessage: `${t("This Field is required")}`
                        }
                    }}
                    value={formData.username}
                    onChange={(e) => setFormData({...formData, username: e.target.value})}
                />
            </Col>
            {selectedTab === "whole_sale" && <Col md={12}>
                <AvField
                    label={t("Company Name")}
                    type='text'
                    placeholder={t("Company Name")}
                    bsSize="lg"
                    name='company_name'
                    validate={{
                        required: {
                            value: true,
                            errorMessage: `${t("This Field is required")}`
                        }
                    }}
                    value={formData.company_name}
                    onChange={(e) => setFormData({...formData, company_name: e.target.value})}
                />
            </Col>}
            {/* Civil ID */}
            {selectedTab === "whole_sale" && <Col md={12} className="mb-3">
                <lable>{t("Civil ID")}</lable>
                <div className="upload">
                    <input 
                        type='file' 
                        name='civil_id'
                        id='civil_id'
                        onChange={(e)=> fileHandler(e, 'civil_id')}
                    />
                    <p>{t("Upload your ID")}</p>
                    {!formData?.civil_id ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.44 8.9C20.04 9.21 21.51 11.06 21.51 15.11V15.24C21.51 19.71 19.72 21.5 15.25 21.5H8.73998C4.26998 21.5 2.47998 19.71 2.47998 15.24V15.11C2.47998 11.09 3.92998 9.24 7.46998 8.91" stroke="#0F50A6" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        <path d="M12 15V3.62" stroke="#0F50A6" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        <path d="M15.35 5.85L12 2.5L8.65002 5.85" stroke="#0F50A6" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        </svg>
                    : <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1.33333C4.32667 1.33333 1.33334 4.32667 1.33334 8C1.33334 11.6733 4.32667 14.6667 8 14.6667C11.6733 14.6667 14.6667 11.6733 14.6667 8C14.6667 4.32667 11.6733 1.33333 8 1.33333ZM11.1867 6.46667L7.40667 10.2467C7.31334 10.34 7.18667 10.3933 7.05334 10.3933C6.92 10.3933 6.79334 10.34 6.7 10.2467L4.81334 8.36C4.62 8.16667 4.62 7.84667 4.81334 7.65333C5.00667 7.46 5.32667 7.46 5.52 7.65333L7.05334 9.18667L10.48 5.76C10.6733 5.56667 10.9933 5.56667 11.1867 5.76C11.38 5.95333 11.38 6.26667 11.1867 6.46667Z" fill="#21AA37"/>
                    </svg>}
                </div>
            </Col>}
            {/* Company Licence */}
            {selectedTab === "whole_sale" && <Col md={12} className="mb-3">
                <lable>{t("Company Licence")}</lable>
                <div className="upload">
                    <input 
                        type='file' 
                        name='company_license' 
                        id='company_license'
                        onChange={(e)=> fileHandler(e, 'company_license')}
                    />
                    <p>{t("Upload your Licence")}</p>
                    {!formData?.company_license ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.44 8.9C20.04 9.21 21.51 11.06 21.51 15.11V15.24C21.51 19.71 19.72 21.5 15.25 21.5H8.73998C4.26998 21.5 2.47998 19.71 2.47998 15.24V15.11C2.47998 11.09 3.92998 9.24 7.46998 8.91" stroke="#0F50A6" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        <path d="M12 15V3.62" stroke="#0F50A6" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        <path d="M15.35 5.85L12 2.5L8.65002 5.85" stroke="#0F50A6" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        </svg>
                    : <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1.33333C4.32667 1.33333 1.33334 4.32667 1.33334 8C1.33334 11.6733 4.32667 14.6667 8 14.6667C11.6733 14.6667 14.6667 11.6733 14.6667 8C14.6667 4.32667 11.6733 1.33333 8 1.33333ZM11.1867 6.46667L7.40667 10.2467C7.31334 10.34 7.18667 10.3933 7.05334 10.3933C6.92 10.3933 6.79334 10.34 6.7 10.2467L4.81334 8.36C4.62 8.16667 4.62 7.84667 4.81334 7.65333C5.00667 7.46 5.32667 7.46 5.52 7.65333L7.05334 9.18667L10.48 5.76C10.6733 5.56667 10.9933 5.56667 11.1867 5.76C11.38 5.95333 11.38 6.26667 11.1867 6.46667Z" fill="#21AA37"/>
                    </svg>}
                </div>
            </Col>}
            {/* Authorized Signatory */}
            {selectedTab === "whole_sale" && <Col md={12} className="mb-3">
                <lable>{t("Authorized Signatory")}</lable>
                <div className="upload">
                    <input 
                        type='file' 
                        name='authorized_signatory' 
                        id='authorized_signatory'
                        onChange={(e)=> fileHandler(e, 'authorized_signatory')}
                    />
                    <p>{t("Upload File")}</p>
                    {!formData?.authorized_signatory ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.44 8.9C20.04 9.21 21.51 11.06 21.51 15.11V15.24C21.51 19.71 19.72 21.5 15.25 21.5H8.73998C4.26998 21.5 2.47998 19.71 2.47998 15.24V15.11C2.47998 11.09 3.92998 9.24 7.46998 8.91" stroke="#0F50A6" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        <path d="M12 15V3.62" stroke="#0F50A6" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        <path d="M15.35 5.85L12 2.5L8.65002 5.85" stroke="#0F50A6" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                        </svg>
                    : <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1.33333C4.32667 1.33333 1.33334 4.32667 1.33334 8C1.33334 11.6733 4.32667 14.6667 8 14.6667C11.6733 14.6667 14.6667 11.6733 14.6667 8C14.6667 4.32667 11.6733 1.33333 8 1.33333ZM11.1867 6.46667L7.40667 10.2467C7.31334 10.34 7.18667 10.3933 7.05334 10.3933C6.92 10.3933 6.79334 10.34 6.7 10.2467L4.81334 8.36C4.62 8.16667 4.62 7.84667 4.81334 7.65333C5.00667 7.46 5.32667 7.46 5.52 7.65333L7.05334 9.18667L10.48 5.76C10.6733 5.56667 10.9933 5.56667 11.1867 5.76C11.38 5.95333 11.38 6.26667 11.1867 6.46667Z" fill="#21AA37"/>
                    </svg>}
                </div>
            </Col>}
            {/* Email */}
            <Col md={12}>
                <AvField
                    label={t("Email")}
                    type='email'
                    placeholder={t("Email")}
                    bsSize="lg"
                    name='email'
                    validate={{
                        required: {
                            value: true,
                            errorMessage: `${t("This Field is required")}`
                        }
                    }}
                    value={formData.email}
                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                />
            </Col>
            {/* Password */}
            <Col md={12} className="position-relative">
                <AvField
                    label={t("Password")}
                    type={showPassword ? 'text' :'password'}
                    placeholder={t("Password")}
                    bsSize="lg"
                    name='password'
                    validate={{
                        required: {
                            value: true,
                            errorMessage: `${t("This Field is required")}`
                        }
                    }}
                    value={formData.password}
                    onChange={(e) => setFormData({...formData, password: e.target.value})}
                />
                {!showPassword ? <span className="showPassword" onClick={()=> setShowPassword(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"/>
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7"/>
                    </svg>
                </span> : <span className="showPassword" onClick={()=> setShowPassword(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z"/>
                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z"/>
                    </svg>
                </span>}
            </Col>
            {/* Phone */}
            <Col md={12}>
                <AvField
                    label={t("Phone")}
                    type='number'
                    placeholder={t("Phone")}
                    bsSize="lg"
                    name='phone'
                    validate={{
                        required: {
                            value: true,
                            errorMessage: `${t("This Field is required")}`
                        }
                    }}
                    value={formData.phone}
                    onChange={(e) => setFormData({...formData, phone: e.target.value})}
                />
            </Col>
        </Row>

        <Button variant="primary" className="signup-btn" type='submit' disabled={loading}>
            {t("Sign Up")}
        </Button>
        <Button variant="secondary" className="login" type='button' onClick={() => setType("login")}>
            {t("Have Account")} ? <span style={{color: 'var(--primary-color)', textDecoration: 'underline'}}>{t("Login")}</span>
        </Button>
    </AvForm>
}
export default Signup;