import React, { useEffect, useState } from 'react';
import CategoriesService from '../../../services/CategoriesService';
import 'swiper/css'
import { useTranslation } from 'react-i18next';
import arrowIcon from "../../../assets/icons/arrow-right.svg"
import CategoryCard from '../../../Enums/CategoryCard';
import { useNavigate } from 'react-router-dom';
import './style.css'

const Categories = () => {
  const [viewAll, setViewAll] = useState(false)
  const [data, setData] = useState([])
  const {t} = useTranslation()
  const navigate = useNavigate()
  const categoriesService = new CategoriesService()

  useEffect(()=>{
    let params = {
      offset: 0,
      limit: 8
    }
    categoriesService?.getList(params).then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data?.map(cat=>{
          return{
            ...cat
          }
        })
        setData(info)
        if(info?.length) setViewAll(true)
      }
    }).catch(()=> {})
  },[])

  return <div className='categories-home'>
      <h1 className='section-title'>{t("categories")}</h1>
      <div className='section-categories row'>
        {data?.slice(0,7)?.map(( category )=> {
          return <div className='col-md-3 col-6 mb-3'>
            <CategoryCard category={category} key={category?.id} />
          </div>
        })}
        {viewAll && <div className='col-md-3 col-6 mb-3' onClick={()=> navigate('/categories')}>
          <div className='d-flex flex-column align-items-center custom-category h-100 justify-content-center'>
            <h1 className='view-all text-white mb-2'>{t('View All')}</h1>
            <img src={arrowIcon} alt='arrow-icon' className='arrowIcon' />
          </div>
        </div>}
      </div>
  </div>

};

export default Categories;
