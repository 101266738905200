import { useEffect, useState } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { AvForm } from "availity-reactstrap-validation"
import { toast } from "react-toastify"
import QuotationService from "../../../services/QuotationService"
import '../style.css'

const ProductQuantity = ({modal, setModal, products, setSelect}) => {
    const [formData, setFormData] = useState([])
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const lang = useSelector(state => state.lang?.lang)
    const quotationService = new QuotationService()

    useEffect(()=>{
        let data = products?.map(res => {
            return {
                ...res,
                quantity: 1,
                amount: res?.location_of_products?.map(num => num.amount).reduce((accumulator, currentValue) => {
                    return accumulator + currentValue;
                }, 0)
            }
        })
        setFormData(data)
    },[])

    const submit = () =>{ 
        let data = {
            products: formData?.map(res=> {
                return {
                    amount: res?.quantity,
                    product_id: res?.id
                  }
            })
        }
        quotationService.create(data).then(res=> {
            if(res?.status){
                toast.success(t('Quotation Send Successfully'))
                setSelect([])
                setModal(false)
            }
        }).catch(error=> console.error(error))
    }

    return <Modal className={lang === 'en' ? 'ltr en' : 'rtl ar'} show={modal} onHide={setModal}>
    <Modal.Body>
        <Modal.Header closeButton className="justify-content-between">
                <Modal.Title>{t("Quantity")}</Modal.Title>
            </Modal.Header>
        <AvForm 
            className="form-checkout form-st guestForm form-horizontal"
            onValidSubmit={submit}
        >
            
                {formData?.map((product, index) => {
                    return <Row style={{borderBottom: '1px solid #dedede'}} className='py-3 productQuality-row' key={product?.id}>
                        <Col md={7}>
                            {lang === 'en' ? product?.name_en : product?.name_ar}
                        </Col>
                        <Col md={5} className='text-end d-flex quality'>
                        <button 
                            className='prod-btn minus p-2' 
                            disabled={product?.amount === 1} 
                            type="button"
                            style={{
                                cursor: product?.amount > 1 ? 'pointer' : 'not-allowed'
                            }}
                            onClick={()=> {
                                let update = formData?.map((res, ind) => {
                                    if(index === ind){
                                        return {
                                            ...res,
                                            quantity: res?.quantity-1
                                        }
                                    } else {
                                        return res
                                    }
                                })
                                setFormData(update)
                            }}
                        >
                            -
                        </button>
                        <input 
                            type='number' 
                            value={product?.quantity} 
                            style={{
                                fontSize: '20px',
                                border: '1px solid #dedede',
                                borderRadius: '5px', width: '5rem',
                            }} 
                            className='mx-3 text-center' 
                            onChange={e => {
                                let update = formData?.map((res, ind) => {
                                    if(index === ind){
                                        return {
                                            ...res,
                                            quantity: e.target.value
                                        }
                                    } else {
                                        return res
                                    }
                                })
                                setFormData(update)
                            }}
                        />
                        <button 
                            className='prod-btn p-2' 
                            disabled={product?.amount === product?.quantity} 
                            style={{cursor: product?.amount === product?.quantity ? 'not-allowed' : 'pointer'} }
                            type="button"
                            onClick={()=> {
                                let update = formData?.map((res, ind) => {
                                    if(index === ind){
                                        return {
                                            ...res,
                                            quantity: res?.quantity+1
                                        }
                                    } else {
                                        return res
                                    }
                                })
                                setFormData(update)
                            }}
                        >
                            +
                        </button>
                        </Col>
                    </Row>
                })}
            <div className="mt-3 d-flex justify-content-between">
                <Button variant="secondary" type="button" onClick={()=> setModal(false)}>{t("Cancel")}</Button>
                <Button variant="primary" disabled={loading} type='submit'>{t("Submit")}</Button>
            </div>
        </AvForm>
    </Modal.Body>
</Modal>
}
export default ProductQuantity