import React, { useEffect, useState } from 'react';
import './style.css'
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ProductsService from '../../../services/ProductsService';
import Loader from '../../../common/Loader';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import ProductCard from '../../../Enums/ProductCard';

const Products = ({type}) => {
  const [products, setProducts] = useState([])
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const {t} = useTranslation()

  const lang = useSelector(state => state?.lang?.lang)
  const productsService = new ProductsService()

  useEffect(()=>{
    let params ={
      offset: 0,
      limit: 8,
    }
    if(type === "Best seller") params['bestSeller']= true
    if(type === "Offers") params['offer']= true
    if(type === "New Arrivals") params['newIn']= true

    setLoader(true)
    productsService?.getList(params).then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data
        setProducts(info)
      }
      setLoader(false)
    }).catch(()=> setLoader(false))
  },[type])

  return (<div className='products-section'>
    <div className='d-flex justify-content-between align-items-center' style={{marginBottom: '32px'}}>
      <h1 className='section-title mb-0'>{type === "Offers" ? t("Special Offers") : t("New Arrivals")}</h1>
      <Link className='view-all' to='/products' state={{type}}>{t('View All')}</Link>
    </div>
    <div>
      {!!products?.length ? <Swiper
        slidesPerView={1.75}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
        className="mySwiper"
      >
        {products?.map((item, index) => {
          return <SwiperSlide key={index}>
            <ProductCard product={item} hasAddToCart={true} />
          </SwiperSlide>
        })}
      </Swiper> : <h3 className='pb-5'>{t(`There's no Products`)}</h3>}
    </div>
    </div>
  );
};

export default Products;
