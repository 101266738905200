import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { refreshFavorite } from '../../store/actions/AuthActions';
import heart from '../../assets/icons/heart.svg';
import heartFill from '../../assets/icons/heart-fill.svg';
import UserService from '../../services/UserService';
import './style.css'

const WholesaleCard = ({product, setSelect, select, setModal}) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const lang = useSelector(state => state?.lang?.lang)
    const dispatch = useDispatch()
    const type = useSelector(state => state.user?.user?.type)
    const wishlistProducts = useSelector(state => state?.user?.favorite)

    const toggleWhishlist = () => {
        let data = {
            product_id: product?.id
        }
        let id = wishlistProducts?.find(res=> res?.id === product?.id)
        if(!!id){
            new UserService()?.removeFavorite(id?.favorite_id).then(res=>{
                if(res?.status){
                    dispatch(refreshFavorite())
                }
            })
        } else {
            new UserService().createFavorite(data).then(res=> {
                if(res?.status){
                    dispatch(refreshFavorite())
                }
            })
        }
    }

    return <div className='wholesale-card'>
        <img src={product.product_images[0]?.url} className='product-image' alt='product' />
        <div className='details'>
            <div>
                <h6 onClick={()=> navigate(`/product/${product?.id}`, { state:{ product }})}>{lang==='en' ? product.name_en : product.name_ar}</h6>
                <div className='no-code'>{t('No. Code')}: {product?.code}</div>
                <div className='prices'>
                    <p className="price">{t("Start From")} <span>{product?.price_two.toFixed(3)} {t("KWD")}</span> </p>
                    {/* <p className="price">{(product.offer ? product.offerPrice : product?.price)} {t("KWD")}</p> */}
                    {/* {product.offer && <p className="old-price">{product.price || ''} {t("KWD")}</p>}
                    {product.offer && <p className="discount">-{(product.price-product.offerPrice)} {t("KWD")}</p>} */}
                    {/* <p className="price">{(product.offer ? product.offerPrice : product?.price)?.toFixed(3)} {t("KWD")}</p> */}
                    {/* {product.offer && <p className="old-price">{product.price?.toFixed(3) || ''} {t("KWD")}</p>} */}
                    {/* {product.offer && <p className="discount">-{(product.price-product.offerPrice)?.toFixed(3)} {t("KWD")}</p>} */}
                </div>
                
                <div className='qty'>{t('QTY')}: {product?.location_of_products[0]?.amount} {t("products")}</div>
            </div>
            <div>
                <p className='brand' style={{height: '21px'}}>
                    {product?.brand_id && t("Brand")}
                    {product?.brand_id && ':'}
                    {product?.brand_id && <span className='mx-2'>
                        {lang==='en' ? product.brand?.name_en : product.brand?.name_ar}
                    </span>}
                </p>
                <button onClick={()=> {
                    if(type === "whole_sale"){
                        setSelect([product])
                        setModal(true)
                    } else {
                        toast.error(t("You have to register as wholesale to in order to proceed"))
                    }
                }} className="btn add-cart btn-block">{t("Send Quotation")}</button>
            </div>
        </div>
        <img 
            src={!wishlistProducts?.find(res=> res?.id === product?.id) ? heart : heartFill } 
            alt='My Wishlist' 
            className='wishlist'
            style={{cursor: 'pointer'}} 
            onClick={toggleWhishlist}
        />
        <input 
            type='checkbox' 
            id={product?.id} 
            className='selectWholesale' 
            checked={select?.find(res=> res?.id === product?.id)}
            onChange={() => {
                if(select?.find(res=> res?.id === product?.id)){
                    let update = select?.filter(res=> res?.id !== product?.id)
                    setSelect(update)
                } else {
                    setSelect([...select, product])
                }
            }}
        />
    </div>
}
export default WholesaleCard