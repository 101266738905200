import { AvField, AvForm } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import userImg from '../../../assets/user1.svg'
import BaseService from "../../../services/BaseService";
import Loader from "../../../common/Loader";
import UserService from "../../../services/UserService";
import { toast } from "react-toastify";
import { updateAvatarAction, updateDataAction } from "../../../store/actions/AuthActions";
import ScrollToTop from "../../../common/ScrollToTop";

const UpdateAccount = ({title}) => {
    const {t} = useTranslation()
    const [formData, setFormData] = useState({
        avatar: '',
        username: '',
        email: '',
        phone: ''
    })
    const userService = new UserService()
    const [pageLoading, setPageLoading] = useState(false)
    const [type, setType] = useState('')
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const dispatch = useDispatch()

    useEffect(()=> {
        setPageLoading(true)
        userService.profile().then(res=>{
            if(res?.status === 200){
                let data = {
                    avatar: res?.data?.data?.avatar,
                    username: res?.data?.data?.username,
                    company_name: res?.data?.data?.company_name || '',
                    email: res?.data?.data?.email,
                    phone: res?.data?.data?.phone,
                }
                setType(res?.data?.data?.type)
                setFormData(data)
            }
            setPageLoading(false)
        }).catch(()=> setPageLoading(false))
    }, [])

    const changeProfile = (e, ) => {
        let filesAll = e.target.files
        const filesData = Object.values(filesAll)
        if(filesData?.length === 0){
            return
        }
        setLoading(true)
        new BaseService().postUpload(filesData[0]).then(res=>{
            if(res?.status === 200){
                let data = {
                    avatar: res.data.url
                }
                userService.updateImageProfile(data).then(res2=>{
                    if(res2.status === 200){
                        setFormData({...formData, avatar: res.data.url})
                        toast.success(t("Profile Image Updated Successfully."))
                        dispatch(updateAvatarAction(data))
                    }
                    setLoading(false)
                }).catch(()=> setLoading(false))
            }
            setLoading(false)
        }).catch(()=> setLoading(false))
    }

    const submit = () => {
        let data ={
            email: formData.email,
            phone: formData.phone,
            username: formData.username
        }
        if(type === "whole_sale") data["company_name"]= formData.company_name
        setSubmitLoading(true)
        userService.update(data).then(res=>{
            if(res?.status === 200){
                toast.success(t('Profile Data Updated Successfully.'))
                dispatch(updateDataAction(data))
            }
            setSubmitLoading(false)
        }).catch(()=> setSubmitLoading(false))
    }

    // if(pageLoading){
    //     return <div style={{width: 'max-content', margin: 'auto', marginTop: '7rem'}}>
    //         <Loader />
    //     </div>
    // }
    return <>
        <ScrollToTop />
        <div className="profile-pic mb-4">
            <p className="tab-title">{t(title)}</p>
            <div className="image-placeholder">	
                <div className="avatar-edit">
                    <input type="file" onChange={(e) => changeProfile(e)} id={`imageUpload`} /> 					
                </div>
                <div className="avatar-preview">
                    {!!formData.avatar ? <div>
                        <img src={formData?.avatar} alt='icon' style={{ width: '110px', height: '110px' }} />
                    </div> : <>
                        {loading && <div className="w-50 m-auto h-50">
                            <Loader />
                        </div>}
                        {!loading && <img src={userImg} alt='icon'
                            style={{ width: '110px', height: '110px' }} />}
                    </>}
                </div>
            </div>
        </div>
        <Row>
            <Col md={8}>
                <AvForm
                className='form-horizontal py-4 px-3'
                onValidSubmit={submit} 
                style={{background: '#F9F9FB'}}>
                    <Row>
                        <Col md={9}>
                            <AvField
                                label={t("Name")}
                                type='text'
                                placeholder={t("Name")}
                                bsSize="lg"
                                name='username'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: `${t("This Field is required")}`
                                    }
                                }}
                                value={formData.username}
                                onChange={(e) => setFormData({...formData, username: e.target.value})}
                            />
                        </Col>
                        {type === "whole_sale" && <Col md={9}>
                            <AvField
                                label={t("Company Name")}
                                type='text'
                                placeholder={t("Company Name")}
                                bsSize="lg"
                                name='company_name'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: `${t("This Field is required")}`
                                    }
                                }}
                                value={formData.company_name}
                                onChange={(e) => setFormData({...formData, company_name: e.target.value})}
                            />
                        </Col>}
                        <Col md={9}>
                            <AvField
                                label={t("Email")}
                                type='email'
                                placeholder={t("Email")}
                                bsSize="lg"
                                name='email'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: `${t("This Field is required")}`
                                    }
                                }}
                                value={formData.email}
                                onChange={(e) => setFormData({...formData, email: e.target.value})}
                            />
                        </Col>
                        <Col md={9}>
                            <AvField
                                label={t("Phone")}
                                type='number'
                                placeholder={t("Phone")}
                                bsSize="lg"
                                name='phone'
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: `${t("This Field is required")}`
                                    }
                                }}
                                value={formData.phone}
                                onChange={(e) => setFormData({...formData, phone: e.target.value})}
                            />
                        </Col>
                        <Col md={9}>
                            <Button variant="primary" className="mt-3 w-100" type='submit' disabled={submitLoading}>
                                {t("Save")}
                            </Button>
                        </Col>
                    </Row>
                </AvForm>
            </Col>
        </Row>
    </>
}
export default UpdateAccount;