import React, { useEffect, useState } from 'react';
import CategoriesService from '../../services/CategoriesService';
import CategoryCard from '../../Enums/CategoryCard';
import './style.css'
import Loader from '../../common/Loader';
import { useTranslation } from 'react-i18next';

const Categories = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const {t} = useTranslation()

  useEffect(()=>{
    setLoading(true)
    new CategoriesService().getList().then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data
        setData(info)
      }
      setLoading(false)
    }).catch(()=> setLoading(false))
  },[])

  return <div className='categories container'>
    <h5 className='type-title'>{t("categories")}</h5>
    {!loading && <div className='products p-0'>
      <div className='row'>
        {!!data?.length && data?.map((category, index)=>{
          return <div className="col-md-3 col-6 mb-4" key={index}>
                <CategoryCard category={category} />
              </div>
            })}
          </div>
    </div>}
    {/* {loading && <Loader />} */}
  </div>
};

export default Categories;
