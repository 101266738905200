import React, { useEffect, useState } from 'react';
import ProductsService from '../../services/ProductsService';
import WholesaleCard from '../../Enums/WholesaleCard';
import { useTranslation } from 'react-i18next';
import ProductQuantity from './ProductQuantity';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CategoriesService from '../../services/CategoriesService';
import './style.css'

const Wholesale = () => {
  const [data, setData] = useState([])
  const [categories, setCategories] = useState([])
  const [select, setSelect] = useState([])
  const [selectCategory, setSelectCategory] = useState("")
  const [hasData, setHasData] = useState(null)
  const [modal, setModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const {t} = useTranslation()
  const type = useSelector(state => state.user?.user?.type)
  const lang = useSelector(state => state?.lang?.lang)

  useEffect(()=>{
    setLoader(true)
    new CategoriesService().getList().then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data
        setCategories(info)
      }
      setLoader(false)
    }).catch(()=> setLoader(false))
  },[])

  useEffect(()=>{
    setLoader(true)
    let params = {}
    if(!!selectCategory) params['category_id'] = selectCategory?.id
    new ProductsService().getList(params).then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data
        if(!!info?.length){ 
          setData(info)
          setHasData(1)
        } else {
          setHasData(0)
          setData([])
        }
      }
      setLoader(false)
    }).catch(()=> setLoader(false))
  },[selectCategory])

  return <div className='categories container'>
    <h5 className='type-title'>{t("Wholesale")} <span>({data?.length} {t("products")})</span></h5>
    <div className='wholesale-alert mb-3'>
      <p>{t("You can select multiple items and request an inquiry for all of them.")}</p>
    </div>
    <div className='products p-0'>
      <div className='tabs'>
        <span
          onClick={()=> setSelectCategory("")}
          className={`tab ${selectCategory === "" ? 'active' : ''}`}
        >
          {t("All")}
        </span>
        {categories?.map((res, index) => {
          return <span 
            key={index}
            onClick={()=> setSelectCategory(res)}
            className={`tab ${res?.id === selectCategory?.id ? 'active' : ''}`}
          >{lang === "en" ? res?.name_en : res?.name_ar}</span>
        })}
      </div>
      <div className='row'>
        {(hasData === 1 && !loader) && data?.map((whole, index)=>{
          return <div className="col-md-3 mb-4" key={index}>
            <WholesaleCard product={whole} setSelect={setSelect} setModal={setModal} select={select} />
          </div>
        })}
        {(hasData === 0 && !loader) && <h3 className='mb-0 mt-5 text-center'>{t("There's no Products")}</h3>}
        {/* {loader && <Loader />} */}
      </div>
    </div>
    {!!select?.length && <div className='inquiry-modal'>
      <div className='d-flex justify-content-between'>
        <p className='mb-0'>{t("You Selected")} {select?.length} {t("Items")}</p>
        <div>
          <Button variant='secondary' className='mx-3' onClick={()=> setSelect([])}>
            {t("Cancel")}
          </Button>
          <Button variant='primary' onClick={()=> {
            if(type === "whole_sale"){
              setModal(true)
            } else {
              toast.error(t("You have to register as wholesale to in order to proceed"))
            }
          }}>
            {t("Send Quotation")}
          </Button>
        </div>
      </div>
    </div>}
    {modal &&  <ProductQuantity modal={modal} setModal={setModal} products={select} setSelect={setSelect} />}
  </div>
};

export default Wholesale;
