import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";   
import logo from '../../assets/logo.png'
import knet from '../../assets/knet.svg'
import visa from '../../assets/visa.svg'
import mastercard from '../../assets/mastercard.svg'
import phone from '../../assets/icons/phone.png'
import './style.css'

const Footer = ({data}) => {
    const {t} = useTranslation()

    return <div className="footer">
        <div>
            <div className="default-section row">
                <div className="col-md-3 one col-12">
                    <img src={logo} alt='logo' width={125} height={100} />
                    <div className="icons mt-3">
                        {!!data.facebook && <a rel="noreferrer" href={data.facebook} target='_blank'>
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_360_4142)">
                                            <path d="M20 40C8.956 40 0 31.044 0 20C0 8.956 8.956 0 20 0C31.044 0 40 8.956 40 20C40 31.044 31.044 40 20 40Z" fill="#F38B35"/>
                                            <path d="M25.2 40.0009V24.5089H30.4L31.18 18.4729H25.2V14.6169C25.2 12.8689 25.684 11.6769 28.192 11.6769H31.388V6.28092C30.836 6.20892 28.936 6.04492 26.728 6.04492C22.12 6.04492 18.964 8.85692 18.964 14.0249V18.4769H13.752V24.5129H18.964V40.0009H25.2Z" fill="white"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_360_4142">
                                            <rect width="40" height="40" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                        </a>}
                        {!!data?.instagram && <a rel="noreferrer" href={data?.instagram} target='_blank'>
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_360_4149)">
                                        <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#F38B35"/>
                                        <path d="M25.7329 15.536C26.4477 15.536 27.0272 14.9565 27.0272 14.2416C27.0272 13.5268 26.4477 12.9473 25.7329 12.9473C25.018 12.9473 24.4385 13.5268 24.4385 14.2416C24.4385 14.9565 25.018 15.536 25.7329 15.536Z" fill="white"/>
                                        <path d="M19.9995 24.7431C22.6188 24.7431 24.7421 22.6198 24.7421 20.0005C24.7421 17.3812 22.6188 15.2578 19.9995 15.2578C17.3802 15.2578 15.2568 17.3812 15.2568 20.0005C15.2568 22.6198 17.3802 24.7431 19.9995 24.7431Z" stroke="white" strokeWidth="1.75781" strokeMiterlimit="10"/>
                                        <path d="M9.97461 23.9262C9.97461 27.4859 12.8603 30.3715 16.4199 30.3715H23.5794C27.1391 30.3715 30.0247 27.4859 30.0247 23.9262V16.0742C30.0247 12.5146 27.1391 9.62891 23.5794 9.62891H16.4199C12.8603 9.62891 9.97461 12.5146 9.97461 16.0742V23.9262Z" stroke="white" strokeWidth="1.75781" strokeMiterlimit="10"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_360_4149">
                                        <rect width="40" height="40" fill="white"/>
                                        </clipPath>
                                        </defs>
                                        </svg>
                        </a>}
                        {!!data?.whatsapp && <a rel="noreferrer" href={`https://wa.me/${data.whatsapp}`} target='_blank'>
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="40" height="40" rx="20" fill="#F38B35"/>
                                        <path d="M21.2188 9C15.2531 9 10.4375 13.8156 10.4375 19.7812C10.4375 22.225 11.2281 24.525 12.7375 26.3938L11.2281 30.9937C11.1562 31.2812 11.2281 31.5688 11.4438 31.7844C11.5156 31.9281 11.7313 32 11.875 32C11.9469 32 12.0906 32 12.1625 31.9281L17.1219 29.7C18.4156 30.275 19.7812 30.5625 21.2188 30.5625C27.1844 30.5625 32 25.7469 32 19.7812C32 13.8156 27.1844 9 21.2188 9ZM27.4719 23.7344C27.1844 24.5969 26.1062 25.3156 25.1719 25.4594C24.9563 25.5312 24.7406 25.5312 24.4531 25.5312C23.8781 25.5312 23.0156 25.3875 21.5062 24.7406C19.7812 24.0219 18.0562 22.5125 16.6906 20.5719V20.5C16.2594 19.8531 15.4688 18.6313 15.4688 17.3375C15.4688 15.7563 16.2594 14.9656 16.5469 14.6063C16.9062 14.2469 17.4094 14.0312 17.9844 14.0312C18.1281 14.0312 18.2 14.0312 18.3438 14.0312C18.8469 14.0312 19.2063 14.175 19.5656 14.8937L19.8531 15.4688C20.0688 16.0437 20.3562 16.6906 20.4281 16.7625C20.6438 17.1938 20.6438 17.5531 20.4281 17.9125C20.3562 18.1281 20.2125 18.2719 20.0687 18.4156C19.9969 18.5594 19.925 18.6312 19.8531 18.6312C19.7812 18.7031 19.7812 18.7031 19.7094 18.775C19.925 19.1344 20.3562 19.7812 20.9313 20.2844C21.7938 21.075 22.4406 21.2906 22.8 21.4344C22.9438 21.2906 23.0875 21.0031 23.3031 20.7875L23.375 20.6438C23.7344 20.1406 24.3094 19.9969 24.8844 20.2125C25.1719 20.3562 26.7531 21.075 26.7531 21.075L26.8969 21.1469C27.1125 21.2906 27.4 21.3625 27.5437 21.65C27.8312 22.2969 27.6156 23.2312 27.4719 23.7344Z" fill="white"/>
                                        </svg>

                        </a>}
                        {!!data.gmail && <a rel="noreferrer" href={`mailto: ${data.gmail}`} target='_blank'>
                                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#F38B35"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10 14.5353V25.8604C10 26.4766 10.451 26.9759 11.0723 26.9759H28.7471C29.3677 26.9759 29.8194 26.4708 29.8194 25.8604V14.5353C29.8194 13.8591 29.4155 13.334 28.7471 13.334H11.0723C10.3781 13.334 10 13.872 10 14.5353ZM11.6297 15.5217C11.6297 15.2488 11.7948 15.0927 12.0587 15.0927C12.2219 15.0927 18.6445 19.1624 19.0335 19.4011L20.0387 20.0262C20.3574 19.8127 20.6774 19.6301 21.0077 19.4088C21.6819 18.9779 27.6806 15.0927 27.8458 15.0927C28.1103 15.0927 28.2748 15.2488 28.2748 15.5217C28.2748 15.8108 27.7181 16.0979 27.3555 16.3191C25.0774 17.7075 22.8 19.2211 20.5445 20.6611C20.4129 20.7501 20.1581 20.9404 19.9671 20.9114C19.7542 20.8785 13.2187 16.6798 12.0297 15.9804C11.851 15.8753 11.6297 15.7791 11.6297 15.5217Z" fill="white"/>
                                        </svg>

                        </a>}
                    </div>
                    <p></p>
                </div>
                <div className="col-md-3 two col-12">
                    <p className="titles">{t("Information")}</p>
                    <Link to='/about-us'>{t("about-us")}</Link>
                </div>
                <div className="col-md-3 three col-12">
                    <p className="titles">{t("Terms")}</p>
                    <Link to='/privacy'>{t("Our Policy")}</Link>
                    <Link to='/terms'>{t("Terms & Conditions")}</Link>
                </div>
                <div className="col-md-3 four col-12">
                    <div className="social d-flex flex-column  justify-content-end">
                            <div>
                                <p className="titles">{t("contact")}</p>
                    
                                <div className="links mb-4">
                                    {/* {!!data?.address &&  */}
                                    <p className="address">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.62 8.45C19.57 3.83 15.54 1.75 12 1.75C12 1.75 12 1.75 11.99 1.75C8.45997 1.75 4.41997 3.82 3.36997 8.44C2.19997 13.6 5.35997 17.97 8.21997 20.72C9.27997 21.74 10.64 22.25 12 22.25C13.36 22.25 14.72 21.74 15.77 20.72C18.63 17.97 21.79 13.61 20.62 8.45ZM12 13.46C10.26 13.46 8.84997 12.05 8.84997 10.31C8.84997 8.57 10.26 7.16 12 7.16C13.74 7.16 15.15 8.57 15.15 10.31C15.15 12.05 13.74 13.46 12 13.46Z" fill="#F38B35"/>
                                        </svg>

                                        <span style={{fontSize: '15px'}}>
                                           {data?.address || 'Kuwait'}
                                        </span>
                                    </p>
                                     {/* } */}
                                     {!!data.call_us && <a rel="noreferrer" style={{fontSize: '15px', gap: '10px'}} className="d-flex mb-3" href={`tel: ${data.gmail}`} target='_blank'>
                                        <img src={phone} alt='phone' width={24} />
                                        {data.call_us}
                                     </a>}
                                     {!!data.gmail && <a rel="noreferrer" style={{fontSize: '15px', gap: '10px'}} className="d-flex mb-1" href={`mailto: ${data.gmail}`} target='_blank'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM17.47 9.59L14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.59C6.21 9.33 6.16 8.85 6.41 8.53C6.67 8.21 7.14 8.15 7.46 8.41L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L16.53 8.41C16.85 8.15 17.33 8.2 17.58 8.53C17.84 8.85 17.79 9.33 17.47 9.59Z" fill="#F38B35"/>
                                        </svg>

                                        {data.gmail}
                                     </a>}
                                </div>
                            </div>
                    </div>
                </div>
                
            </div>
            <div className="power-div d-flex justify-content-between align-items-center">
                <div className="visa">
                    <img src={visa} alt='visa' />
                    <img src={mastercard} alt='mastercard' className="mx-2" />
                    <img src={knet} alt='knet' />
                </div>
                <div>
                    <p className="text-center power mb-0">{t("Powered By")}
                    <a rel="noreferrer" 
                        href='https://www.cloudliftsolution.com/' 
                        target='_blank'
                        style={{
                            color: '#000',
                            textDecoration: 'none',
                            margin: '0 5px'
                        }}
                    >Cloud Lift Solutions</a>
                    </p>
                </div>
                <div></div>
            </div>
        </div>
    </div>
}
export default Footer;