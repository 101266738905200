import { Button, Col, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import QuotationService from "../../../../services/QuotationService";
import { toast } from "react-toastify";
import { useState } from "react";

const RejectedModal = ({addModal, setAddModal, id, setShouldUpdate})=>{
    const [reason, setReason] = useState('')
    const lang = useSelector(state=> state.lang?.lang)
    const {t} = useTranslation()
    const quotationService = new QuotationService()

    const confirm = () => {
        let data = {
            userStatus: "rejected",
            quotation_id: id
        }
        if(!!reason) data["reason"] = reason
        quotationService.responseQuotation(data).then(res=> {
            if(res?.status){
                toast.success(t("Quotation Rejection sent successfully"))
                setShouldUpdate(prev=> !prev)
                setAddModal(false)
            }
        })
    }

    return(
        <Modal className={lang === 'en' ? "en fade" : "ar fade"} style={{textAlign: lang === 'en' ? 'left' : 'right'}} show={addModal} onHide={()=>{
            setAddModal()
            }}>
            <Modal.Header>
            <Modal.Title>{t("Quotation Rejection")}</Modal.Title>
            <Button
                variant=""
                // className="close"
                style={{
                    fontSize: '1.5rem'
                }}
                onClick={()=>{
                    setAddModal()
                }}
                >
                <span>&times;</span>
            </Button>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <label className="mb-2">{t("Reason")}({t("optional")})</label>
                        <textarea
                            placeholder={t("Reason")}
                            value={reason}
                            onChange={e=> setReason(e.target.value)}
                            style={{
                                width: '100%',
                                height: '150px',
                                maxHeight: '250px',
                                border: '1px solid #dedede',
                                borderRadius: '8px',
                                padding: '8px 12px'
                            }}
                        ></textarea>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={confirm} variant="primary light" className="w-100">
                {t("Confirm")}
            </Button>
            </Modal.Footer>
        </Modal>)
}

export default RejectedModal;