import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";
import ProductsService from "../../../services/ProductsService";
import { Badge, Col, Row } from "react-bootstrap";
import './style.css'
import QuotationService from "../../../services/QuotationService";
import ScrollToTop from "../../../common/ScrollToTop";

const MyQuotation = ({setQutation, setQutationStatus, title}) => {
    const [data, setData] = useState([])
    const [shouldUpdate, setShouldUpdate] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const lang = useSelector(state => state?.lang?.lang)

    useEffect(()=>{
        setLoading(true)
        new QuotationService().getList().then(res=>{
        if(res?.status === 200){
            let info = res?.data?.data?.data
            setData(info)
        }
        setLoading(false)
        }).catch(()=> setLoading(false))
    },[])

    // if(loading){
    //     return <div className="d-flex justify-content-center mt-5">
    //         <Loader />
    //     </div>
    // }

    return <div className="quotation">
        <ScrollToTop />
        <div>
            <p className="tab-title">{t(title)}</p>
            
            <div className="mt-4">
                {data?.map((info, ind) => {
                    return <Row key={ind} className="qutaion-card mb-4" onClick={()=> {
                            setQutation(info)
                            setQutationStatus(true)
                            }}
                        >
                        {info?.sub_quotations?.map((res => {
                            return <>
                                <Col md={4} className="mb-3">
                                    <div className="d-flex" style={{gap: '12px', boxShadow: '0 0 4px #dedede91'}}>
                                        <img src={res?.product?.product_images[0]?.url} alt='product' width='100' height='100' />
                                        <div className="p-2">
                                            <p className='mb-1'>{lang === 'en' ? res?.product?.name_en : res?.product?.name_ar}</p>
                                            <span>{t('Amount')}: {res?.amount}</span>
                                        </div>
                                    </div>
                                </Col>
                            </>
                        }))}
                        <Col md={12} className='d-flex mt-3 justify-content-between py-2' style={{borderTop: '1px solid #dedede'}}>
                            <p className='m-0'>{t("Reference ID")}: #{info?.reference_id}</p>
                            <div>
                                <Badge 
                                    bg={
                                        info?.esen_status === 'provided' ? 'secondary' : 'primary'
                                    }
                                    className='text-capitalize mx-2 p-2'
                                >
                                    {info?.esen_status}
                                </Badge>
                                <Badge bg={
                                    info?.user_status === "new" ? 'primary' : 
                                    info?.user_status === "pending" ? 'warning' : 
                                    info?.user_status === 'accepted' ? 'success' : 'danger'} className="p-2">
                                    {t(info?.user_status)}
                                </Badge>

                            </div>
                        </Col>
                    </Row>
                })}
            </div>
        </div>
    </div>
}
export default MyQuotation;