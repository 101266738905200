import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useDispatch, useSelector } from 'react-redux';
import { checkAutoLogin } from './services/AuthService';
import Navbar from './Pages/Navbar';
import Home from './Pages/Home'
import Footer from './Pages/Footer'
import Categories from './Pages/Categories';
import AboutUs from './Pages/AboutUs';
import Products from './Pages/Products';
import ScrollToTop from './common/ScrollToTop';
import Product from './Pages/Product';
import Error404 from './common/Error404';
import Cart from './Pages/Cart';
import Checkout from './Pages/Checkout';
import Profile from './Pages/Profile';
import './i18n';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "react-toastify/dist/ReactToastify.css";
import Brands from './Pages/Brands';
import OrderFailed from './common/OrderFailed';
import OrderSuccessful from './common/OrderSuccessful';
import TopNavbar from './Pages/Navbar/TopNavbar';
import Wholesale from './Pages/Wholesale';
import SocialMediaService from './services/SocialMediaService';
import './App.css';
import UserService from './services/UserService';
import { setFavorite } from './store/actions/AuthActions';

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const lang = useSelector(state=> state?.lang?.lang)
  const user = useSelector(state => state?.user?.user)
  const refreshWishlistProducts = useSelector(state => state?.user?.refreshFav)
  const [data, setData] = useState({})
  console.log(user)
  useEffect(() => {
    AOS.init();
    checkAutoLogin(dispatch, navigate, location?.pathname);
  }, []);

  useEffect(()=>{
    new SocialMediaService().getList().then(res=>{
        if(res?.status === 200) setData(res?.data?.data)
    })
  },[])

  useEffect(()=>{
    if(!!user){
      new UserService().getFavorite().then(res=>{
        if(res?.status){
          dispatch(setFavorite(res?.data?.data?.data?.map(info => {
            return {
              ...info.product,
              favorite_id: info?.id
            }
          })))
        }
      })
    }
  },[refreshWishlistProducts, user])

  return (
    <div className={`App ${lang}`}>
        <ToastContainer />
        <ScrollToTop />
        <TopNavbar data={data} />
        <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/categories' element={<Categories />} />
            <Route path='/products' element={<Products />} />
            <Route path='/category/:id' element={<Products />} />
            <Route path='/brand/:id' element={<Products />} />
            <Route path='/wholesale' element={<Wholesale />} />
            <Route path='/product/:id' element={<Product />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/brands' element={<Brands />} />
            <Route path='/checkout' element={<Checkout />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/privacy' element={<AboutUs />} />
            <Route path='/terms' element={<AboutUs />} />
            <Route path='/profile' element={<Profile />} /> 
            {/* 
            <Route path='/brand/:id' element={<Products />} />
            <Route path='/branches' element={<Branches />} />
            */}

            <Route path='/order-successful' element={<OrderSuccessful />} />
            <Route path='/order-failed' element={<OrderFailed />} />
            <Route path='*' element={<Error404 />} />
          </Routes>
        <Footer data={data} />
    </div>
  );
}

export default App;
