import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './style.css'
import { Button } from 'react-bootstrap';
import BrandsService from '../../services/BrandsService';
import Loader from '../../common/Loader';

const Brands = () => {
  const [data, setData] = useState([])

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {t} = useTranslation()
  const lang = useSelector(state => state?.lang?.lang)

  useEffect(()=>{
    setLoading(true)
    new BrandsService()?.getList().then(res=>{
      if(res?.status === 200){
        let info = res?.data?.data?.data
        setData(info)
      }
      setLoading(false)
    }).catch(()=> setLoading(false))
  },[])

  return <div className='brands-section'>
        <h5 className='type-title'>
          <span>{t("brands")}</span>
        </h5>
        <div className='brands'>
          {!loading && <div className='row'>
            {data?.map((brand, index)=>{
              return <div className="col-md-3 brand col-6" key={index}>
                <div className='brand-card'>
                  <img src={brand.image} alt='brand' className='brand-image w-100' />
                  <div>
                    <p>{lang === 'en' ? brand?.name_en : brand?.name_ar}</p>
                    <button onClick={()=> navigate(`/brand/${brand.id}`)} className='more-details'>{t("more Details")}</button>
                  </div>
                </div>
              </div>
            })}
          </div>}

          {/* {loading && <Loader />} */}
        </div>
  </div>
};

export default Brands;
