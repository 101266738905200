import React from 'react'
import { Link } from 'react-router-dom'
import ErrorImage from '../assets/404.png'
import { useTranslation } from 'react-i18next'

const Error404 = () => {
  const {t} = useTranslation()

  return (
    <div className='error-page'>
      <div className='container'>
        <div className='form-input-content text-center'>
              <h1 className='text-primary'>404</h1>
              <h4>
                {t("page not found!")}
              </h4>
              <img src={ErrorImage} alt='ErrorImage' />
              <div>
                <Link className='btn btn-primary' to='/'>
                  {t("try again")}
                </Link>
              </div>
            </div>
      </div>
    </div>
  )
}

export default Error404
