import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../services/AuthService";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import UpdateAccount from "./UpdateAccount";
import ChangePassword from "./ChangePassword";
import MyOrders from "./MyOrders";
import Address from "./Address";
import './style.css'
import MyQuotation from "./MyQuotation";
import QuotationDetails from "./MyQuotation/Details";
import Wishlist from "./Wishlist";

const Profile = () => {
    const {t} = useTranslation()
    const auth = useSelector(state => state?.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const tabs = ["Update Account", "Change Password", "My Orders", "My Quotation", "Addresses", "Wishlist"]
    const [selectedTab, setSelectedTab] = useState("Update Account")
    const [qutation, setQutation] = useState([])
    const [qutationStatus, setQutationStatus] = useState(false)
    const type = useSelector(state => state.user?.user?.type)

    useEffect(()=>{
        if(location?.state?.myOrder === 'myOrder') setSelectedTab("My Orders")
    },[location])

    return <div className='profile'>
        <div className="row">
            <div className="col-md-3 col-12">
                <div className="profile-details">
                    <div className="welcome">
                        <p className="welcome-title">{t("Hi")} {type === "whole_sale" ? auth?.user?.company_name : auth?.user?.username}</p>
                        <p className="welcome-email">{auth?.user?.email}</p>
                    </div>
                    <hr />
                    <div className="profile-links">
                        <p className={`${tabs[0]===selectedTab ? 'active' : ''} `} onClick={()=> setSelectedTab(tabs[0])}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.1207 12.7805C12.0507 12.7705 11.9607 12.7705 11.8807 12.7805C10.1207 12.7205 8.7207 11.2805 8.7207 9.51047C8.7207 7.70047 10.1807 6.23047 12.0007 6.23047C13.8107 6.23047 15.2807 7.70047 15.2807 9.51047C15.2707 11.2805 13.8807 12.7205 12.1207 12.7805Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18.7398 19.3805C16.9598 21.0105 14.5998 22.0005 11.9998 22.0005C9.39977 22.0005 7.03977 21.0105 5.25977 19.3805C5.35977 18.4405 5.95977 17.5205 7.02977 16.8005C9.76977 14.9805 14.2498 14.9805 16.9698 16.8005C18.0398 17.5205 18.6398 18.4405 18.7398 19.3805Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            {t(tabs[0])}
                        </p>
                        <p className={`${tabs[1]===selectedTab ? 'active' : ''} `} onClick={()=> setSelectedTab(tabs[1])}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.1207 12.7805C12.0507 12.7705 11.9607 12.7705 11.8807 12.7805C10.1207 12.7205 8.7207 11.2805 8.7207 9.51047C8.7207 7.70047 10.1807 6.23047 12.0007 6.23047C13.8107 6.23047 15.2807 7.70047 15.2807 9.51047C15.2707 11.2805 13.8807 12.7205 12.1207 12.7805Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18.7398 19.3805C16.9598 21.0105 14.5998 22.0005 11.9998 22.0005C9.39977 22.0005 7.03977 21.0105 5.25977 19.3805C5.35977 18.4405 5.95977 17.5205 7.02977 16.8005C9.76977 14.9805 14.2498 14.9805 16.9698 16.8005C18.0398 17.5205 18.6398 18.4405 18.7398 19.3805Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            {t(tabs[1])}
                        </p>
                        <p className={`${tabs[2]===selectedTab ? 'active' : ''} `} onClick={()=> setSelectedTab(tabs[2])}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15.75 9H8.25" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15.75 15H8.25" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            {t(tabs[2])}
                        </p>
                        {type === "whole_sale" && <p className={`${tabs[3]===selectedTab ? 'active' : ''} `} onClick={()=> {
                            setQutationStatus(false)
                            setSelectedTab(tabs[3])
                        }}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 0C19.552 0 20 0.448 20 1V15C20 15.552 19.552 16 19 16H4.455L0 19.5V1C0 0.448 0.448 0 1 0H19ZM18 2H2V15.385L3.763 14H18V2ZM8.515 4.412L8.962 5.1C7.294 6.003 7.323 7.452 7.323 7.764C7.478 7.744 7.641 7.74 7.803 7.755C8.705 7.839 9.416 8.58 9.416 9.5C9.416 10.466 8.632 11.25 7.666 11.25C7.129 11.25 6.616 11.005 6.292 10.66C5.777 10.114 5.5 9.5 5.5 8.505C5.5 6.755 6.728 5.187 8.515 4.412ZM13.515 4.412L13.962 5.1C12.294 6.003 12.323 7.452 12.323 7.764C12.478 7.744 12.641 7.74 12.803 7.755C13.705 7.839 14.416 8.58 14.416 9.5C14.416 10.466 13.632 11.25 12.666 11.25C12.129 11.25 11.616 11.005 11.292 10.66C10.777 10.114 10.5 9.5 10.5 8.505C10.5 6.755 11.728 5.187 13.515 4.412Z" fill="#1E1F24"/>
                            </svg>

                            {t(tabs[3])}
                        </p>}
                        <p className={`${tabs[4]===selectedTab ? 'active' : ''} `} onClick={()=> setSelectedTab(tabs[4])}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.0009 13.4295C13.724 13.4295 15.1209 12.0326 15.1209 10.3095C15.1209 8.58633 13.724 7.18945 12.0009 7.18945C10.2777 7.18945 8.88086 8.58633 8.88086 10.3095C8.88086 12.0326 10.2777 13.4295 12.0009 13.4295Z" stroke="#292D32" strokeWidth="1.5"/>
                                <path d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z" stroke="#292D32" strokeWidth="1.5"/>
                            </svg>

                            {t(tabs[4])}
                        </p>
                        <p className={`${tabs[5]===selectedTab ? 'active' : ''} `} onClick={()=> setSelectedTab(tabs[5])}>
                            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.62 18.8096C11.28 18.9296 10.72 18.9296 10.38 18.8096C7.48 17.8196 1 13.6896 1 6.68961C1 3.59961 3.49 1.09961 6.56 1.09961C8.38 1.09961 9.99 1.97961 11 3.33961C12.01 1.97961 13.63 1.09961 15.44 1.09961C18.51 1.09961 21 3.59961 21 6.68961C21 13.6896 14.52 17.8196 11.62 18.8096Z" stroke="#1E1F24" stroke-width="1.5" strokeLinecap="round" stroke-linejoin="round"/>
                            </svg>

                            {t(tabs[5])}
                        </p>
                    </div>
                    <hr />
                    <button className="logout" onClick={()=> {
                            Logout(auth?.accessToken, dispatch)
                            navigate('/')
                        }}>
                            {t("Logout")}
                        </button>
                </div>
            </div>
            <div className="col-md-9 col-12">
                <div className="details mb-4">
                    {selectedTab === "My Orders" && <MyOrders title={selectedTab} />}
                    {selectedTab === "Update Account" && <UpdateAccount title={selectedTab}  />}
                    {selectedTab === "Change Password" && <ChangePassword title={selectedTab}  />}
                    {selectedTab === "Addresses" && <Address title={selectedTab}  />}
                    {(selectedTab === "My Quotation" && !qutationStatus) && <MyQuotation setQutationStatus={setQutationStatus} setQutation={setQutation} title={selectedTab}  />}
                    {(selectedTab === "My Quotation" && qutationStatus) && <QuotationDetails qutation={qutation} setQutationStatus={setQutationStatus} />}
                    {selectedTab === "Wishlist" && <Wishlist title={selectedTab} />}
                </div>
            </div>
        </div>
    </div>
}
export default Profile;