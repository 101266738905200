export const ar = {
    "customer": "العميل", 
    "whole_sale": "الجملة",
    "home": "الصفحه الرئيسيه",
    "products": "المنتجات",
    "categories": "الأقسام",
    "about-us": "من نحن",
    "quick_links": "روابط سريعة",
    "Brand": "ماركة",
    "Company Name": "اسم الشركة",
    "Hi": "مرحبا",
    "Register Successfully.": "تم التسجيل بنجاح.",
    "Login Successfully.": "تسجيل الدخول بنجاح.",
    "Profile Image Updated Successfully.": "تم تحديث صورة الملف الشخصي بنجاح.",
    "Profile Data Updated Successfully.": "تم تحديث بيانات الملف الشخصي بنجاح.",
    "Logout Successfully.": "تسجيل الخروج بنجاح.",
    "Civil ID": "البطاقة المدنية",
    "Upload your ID": "تحميل بطاقة هويتك",
    "Upload your Licence": "تحميل ترخيصك",
    "Upload File": "تحميل الملف",
    "Company Licence": "رخصة الشركة",
    "Authorized Signatory": "المفوض بالتوقيع",
    "Shop By Category": "تسوق حسب القسم",
    "View All": "عرض الكل",
    "Search by Product": "البحث حسب المنتج",
    "previous": "السابق",
    "Description": "وصف",
    "Dynamic Variant": "اضافات بسعر",
    "Similar Products": "منتجات مماثلة",
    "next": "التالي",
    "Information": "معلومة",
    "Terms": "شروط",
    "page not found!": "لم يتم العثور على الصفحة!",
    "try again": "حاول ثانية",
    "Our Policy": "سياستنا",
    "Terms & Conditions": "الشروط والأحكام",
    "Cancel Message": "هل أنت متأكد من أنك تريد إلغاء هذا الطلب؟ لا يمكن التراجع عن هذا الإجراء، وسيتم فقد أي تقدم متعلق بالطلب.",
    "results": "نتائج",
    "Explore All": "استكشاف الكل",
    "Explore": "استكشف",
    "more Details": "المزيد من التفاصيل",
    "Feature Products": "المنتجات المميزة",
    "Pending": "قيد الانتظار",
    "pending": "قيد الانتظار",
    "Best Selling Products": "أفضل المنتجات مبيعا",
    'Featured Products': "المنتجات المميزة", 
    "There's no Products": "لا يوجد منتجات",
    "Working Hours": "ساعات العمل",
    "Reference ID": "رقم المرجع",
    "Rejected": "مرفوض",
    "ordered successfully": "شكرًا لك على طلبك",
    "ordered successfully description": "تم تقديم طلبك بنجاح",
    "ordered failed": "حدث خطأ ما!",
    "ordered failed description": "لم يتم استكمال الدفع",
    "Cancel": "إلغاء",
    "Cash in Delivery": "نقدا عند التسليم",
    "canceled": "تم الإلغاء",
    "out of stock": "نفذ من المخزن",
    "Working Days": "أيام العمل",
    "Show Rooms": "صالات العرض",
    "Best seller": "الأكثر مبيعًا", 
    'Offers': "العروض", 
    'branches': 'الفروع', 
    'brands': 'العلامات التجارية', 
    "Contacts": 'جهات الاتصال',
    "Follow us": 'تابعنا',
    'New Arrivals': "المنتجات الجديدة",
    "Style Up Your Looks": "صمم مطبخك",
    "All Rights": "كل الحقوق محفوظة",
    "Hasan Al Naser": "حسن الناصر",
    "No. Code": "رقم الكود",
    "QTY": "الكمية",
    "Start From": "البدء من",
    "Send Quotation": "إرسال عرض الأسعار",
    "Powered By": "مدعوم من",
    "Total Price": "السعر الإجمالي",
    "Continue": "متابعة",
    "Sort by": "فرز حسب",
    "price": "السعر",
    "Low to High": "منخفض إلى مرتفع",
    "High to Low": "من مرتفع إلى منخفض",
    "A-Z": "أ-ي",
    "Z-A": "ي-أ",
    "Our History": "تاريخنا", 
    "Our Values": "قيمنا", 
    "Our Clients": "عملاؤنا", 
    "Company Profile": "نبذة عن الشركة",
    "Your Cart is Empty": "عربة التسوق فارغة",
    "KWD": "د.ك",
    "You can select multiple items and request an inquiry for all of them.": "يمكنك تحديد عناصر متعددة وطلب الاستعلام عن جميعها.",
    "Wholesale": "بالجملة",
    "Filter": "فلتر",
    "Download": "تحميل",
    "You have to register as wholesale to in order to proceed": "يجب عليك التسجيل كبائع جملة حتى تتمكن من المتابعة",
    "Style Up": "صمم",
    "Add To Cart": "أضف إلى السلة",
    "Cart": "السلة",
    "Save": "حفظ",
    "contact": "اتصل بنا",
    "Name": "الإسم",
    "Email": "البريد الإلكتروني",
    "Phone": "رقم الموبايل",
    "Message": "الرسالة",
    "CONTACT US ON WHATSAPP": "تواصل معنا عبر واتساب",
    "GET IN TOUCH": "ابق على تواصل",
    "Product": "المنتج",
    "Price": "السعر",
    "Quantity": "الكمية",
    "Total": "الإجمالي",
    "Sub Total": "المجموع الفرعي",
    "Payment": "الدفع",
    "Payment Details": "تفاصيل الدفع",
    "Proceed": "هل ترغب في المتابعة لإتمام الطلب كـ",
    "Login": "تسجيل الدخول",
    "Continue as a Guest": "المتابعة كزائر",
    "Login Into Your Account": "تسجيل الدخول إلى حسابك",
    "Create New Account": "إنشاء حساب جديد",
    "Password": "الرقم السري",
    "Don't Have Account": "ليس لديك حساب",
    "Sign Up": "التسجيل",
    "Have Account": "لديك حساب",
    "Checkout": "إتمام الشراء",
    "User Details": "تفاصيل المستخدم",
    "Full Name": "الاسم الكامل",
    "Address Details": "تفاصيل العنوان",
    "Area": "المنطقة",
    "Block": "البلوك",
    "Street": "الشارع",
    "Avenue": "الجادة",
    "Floor Number": "رقم الطابق",
    "Optional": "اختياري",
    "Address Type": "نوع العنوان",
    "Building Number": "رقم المبنى",
    "Building": "المبنى",
    "Floor": "الطابق",
    "Apartment Number": "رقم الشقة",
    "House Number": "رقم المنزل",
    "Office Number": "رقم المكتب",
    "Extra Directions": "اتجاهات إضافية",
    "Other Instructions": "تعليمات أخرى",
    "Please Enter": "الرجاء إدخال",
    "Please Select": "الرجاء التحديد",
    "House": "المنزل",
    "Flat": "شقه",
    "Office": "المكتب",
    "Coupon Code": "رمز الكوبون",
    "Apply": "تطبيق",
    "Payment Method": "طريقة الدفع",
    "KNET": "كي نت",
    "You Selected": "قمت باختيار",
    "Items": "عناصر",
    "Visa/MasterCard": "فيزا/ماستركارد",
    "Payment Detail": "تفاصيل الدفع",
    "Discount": "الخصم",
    "Delivery Charges": "رسوم التوصيل",
    "Submit Order": "ارسال الطلب",
    "Submit": "ارسال",
    "show some love to your bag": "أظهر بعض الحب لحقيبتك",
    "Fill your bag with things that make you happy": "املأ حقيبتك بالأشياء التي تسعدك",
    "Profile": "الصفحه الشخصيه",
    "Logout": "تسجيل الخروج",
    "My Account": "حسابي",
    "My Orders": "طلباتي",
    "Update Account": "تحديث الحساب",
    "Update": "تحديث",
    "Change Password": "تغيير كلمة المرور",
    "New Password": "كلمة المرور الجديدة",
    "Old Password": "كلمة المرور القديمه",
    "Quotation Send Successfully": "تم إرسال عرض الأسعار بنجاح.",
    "Confirm New Password": "تأكيد كلمة المرور الجديدة",
    "Confirm": "تأكيد",
    "All": "الكل",
    "This Field is required": "هذه الخانة مطلوبه",
    "Amount": "كميه",
    "privacy": "الخصوصية",
    "terms and conditions": "الشروط والأحكام",
    "Add": "إضافة",
    "Buy it again": "اشتري مره أخري",
    "Add Address First": "اضف العنوان اولا",
    "There are No Products": "لا يوجد منتجات",
    "Product Added To Cart": "تم إضافة المنتج إلى سلة التسوق",
    "Forget Password?": "هل نسيت كلمه المرور؟",
    "Default": "الافتراضية",
    "Forget Password": "نسيت كلمه المرور",
    "Back": "الي الخلف",
    "Delete": "حذف",
    "Address Added Successfully.": "تمت إضافة العنوان بنجاح.",
    "Address Updated Successfully.": "تمت تعديل العنوان بنجاح.",
    "Address Deleted Successfully.": "تمت حذف العنوان بنجاح.",
    "Message Sent Successfully.": "تم إرسال الرسالة بنجاح.",
    "First Name": "الاسم الأول",
    "Last Name": "اسم العائلة",
    "Message Required": "الرسالة المطلوبة",
    "promo code is invalid!": "الرمز الترويجي غير صالح!",
    "Successfully Applied": "تم التطبيق بنجاح",
    "Remove from Cart": "إزالة من السلة",
    "Remove": "إزاله",
    "Search": "بحث",
    "Addresses": "العناوين",
    "Classic kitchen": "مطبخ كلاسيكي",
    "Modern kitchen": "المطبخ الحديث",
    "No Address": "لا يوجد عنوان",
    "Add New Address": "إضافة عنوان جديد",
    "Set Default": "تعيين كافتراضي",
    "Edit": "تعديل",
    "Date": "التاريخ",
    "See More": "شاهد المزيد",
    "Wishlist": "قائمة الرغبات",
    "My Quotation": "عرض الأسعار",
    "Quotation": "عرض الأسعار",
    "Your quotation is ready, Please download.": "عرض الأسعار الخاص بك جاهز، يرجى التنزيل.",
    "Quotation Details": "تفاصيل عرض الأسعار",
    "Approve & Add to Cart": "الموافقة والإضافة إلى سلة التسوق",
    "Reject": "رفض",
    "Reason": "السبب",
    "Quotation Rejection": "رفض عرض الأسعار",
    "optional": "اختياري",
    "new": "جديد",
    "accepted": "مقبول",
    "rejected": "مرفوض",
    "Quotation Rejection sent successfully": "تم رفض عرض الأسعار بنجاح",
    "Quotation is Pending": "عرض الأسعار معلق",
    "Quotation is Accepted": "تم قبول عرض الأسعار",
    "Special Offers": "العروض الخاصة",
}