import Categories from "./Categories"
import Products from "./Products"
import Header from "./Header"

const Home = () => {
    return <>
        <Header />
        <Categories />
        <Products type='Offers' />
        <Products type='New Arrivals' />
    </>
}
export default Home