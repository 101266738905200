import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import BannerService from '../../../services/BannerService';
import Loader from '../../../common/Loader'; 
import './style.css'

function Header() {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const bannerService = new BannerService()

    useEffect(()=>{
      setLoader(true)
      bannerService?.getList().then(res=>{
        if(res?.status === 200){
          let data = res?.data?.data
          setData(data)
        }
        setLoader(false)
      }).catch(()=> setLoader(false))
    },[])

  return (
    <div className='header-home'>
      {/* {loader ? <div className='d-flex justify-content-center py-5'>
        <Loader />
      </div> :  */}
      <div>
      <Carousel data-bs-theme="dark" controls='false' touch={'true'}>
          {data?.map((item, index)=>{
              return <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={item?.image}
                alt="slide"
              />
              <div className='title'>
                {!!item?.catalog && <span>{item?.catalog}</span>}
                {!!item?.title && <p>{item?.title}</p>}
              </div>
            </Carousel.Item>
          })}
      </Carousel>
      </div>
    </div>
  );
}

export default Header;