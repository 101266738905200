import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CartService from '../../services/CartService';
import { addFavorite, addToCart, refreshFavorite, removeFavorite } from '../../store/actions/AuthActions';
import heart from '../../assets/icons/heart.svg';
import heartFill from '../../assets/icons/heart-fill.svg';
import './style.css'
import UserService from '../../services/UserService';
import { useState } from 'react';

const ProductCard = ({product, hasAddToCart}) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const lang = useSelector(state => state?.lang?.lang)
    const user = useSelector(state => state?.user)
    const wishlistProducts = useSelector(state => state?.user?.favorite)
    const dispatch = useDispatch()
    const cartService = new CartService()
    const userService = new UserService()

    const addCart = () => {
        let data = {
            products: [{
                amount: 1,
                product_id: product?.id
            }]
        }

        if(!!user?.user){
            cartService.create(data).then(res=>{
                if(res?.status === 201){
                    toast.success(t("Product Added To Cart"));
                    dispatch(addToCart({
                        ...product,
                        in_stock: product?.location_of_products[0]?.amount,
                        amount: 1
                    }))
                }
            }).catch(e=> {
                if(e.response?.data?.message === "product_is_already_Exist_in_your_cart"){
                    let data ={
                        product_id: product.id,
                        amount: 1
                    }
                    cartService.update(data).then(res=>{
                        if(res?.status === 200){
                            toast.success(t("Product Added To Cart"));
                        }
                    })
                    return
                }
                toast.error(e.response?.data?.message?.replaceAll('_', ' '))
            })
        } else {
            toast.success(t("Product Added To Cart"));
            dispatch(addToCart({
                ...product,
                in_stock: product?.location_of_products[0]?.amount,
                amount: 1
            }))
        }
    }

    const toggleWhishlist = () => {
        if(!user?.user){
            return
        }
        let data = {
            product_id: product?.id
        }
        let id = wishlistProducts?.find(res=> res?.id === product?.id)
        if(!!id){
            userService?.removeFavorite(id?.favorite_id).then(res=>{
                if(res?.status){
                    dispatch(refreshFavorite())
                }
            })
        } else {
            userService.createFavorite(data).then(res=> {
                if(res?.status){
                    dispatch(refreshFavorite())
                }
            })
        }
    }

    return <div className='product-card'>
        <img src={product.product_images[0]?.url} className='product-image' alt='product' />
        <div className='details'>
            <div>
                <h6 onClick={()=> navigate(`/product/${product?.id}`, { state:{ product }})}>{lang==='en' ? product.name_en : product.name_ar}</h6>
                <div className='prices'>
                    <p className="price">{(product.offer ? product.offerPrice?.toFixed(2) : product?.price_one?.toFixed(2))} {t("KWD")}</p>
                    {product.offer && <p className="old-price">{product.price_one?.toFixed(2) || ''} {t("KWD")}</p>}
                    {product.offer && <p className="discount">-{(product.price_one-product.offerPrice).toFixed(2)} {t("KWD")}</p>}
                </div>
            </div>
            <div>
                <p className='brand' style={{height: '21px'}}>
                    {product?.brand_id && t("Brand")}
                    {product?.brand_id && ':'}
                    {product?.brand_id && <span className='mx-2'>
                        {lang==='en' ? product.brand?.name_en : product.brand?.name_ar}
                    </span>}
                </p>
                {hasAddToCart && <>
                {!!product?.location_of_products[0]?.amount && <button onClick={addCart} className="mt-2 btn add-cart btn-block">{t("Add To Cart")}</button>}
                {!product?.location_of_products[0]?.amount && <p className="text-danger text-center">{t("out of stock")}</p>}
                </>}
            </div>
        </div>
        <img 
            src={!wishlistProducts?.find(res=> res?.id === product?.id) ? heart : heartFill } 
            alt='My Wishlist' 
            className='wishlist'
            style={{cursor: 'pointer'}} 
            onClick={toggleWhishlist}
        />
    </div>
}
export default ProductCard