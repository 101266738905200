import { useState } from "react"
import { Modal } from "react-bootstrap"
import Login from "./Login";
import './style.css'
import Signup from "./Signup";
import { useSelector } from "react-redux";

const Authentication = ({modal, setModal}) => {
    const [type, setType] = useState("login")
    const lang = useSelector(state=> state?.lang?.lang)

    return <Modal className={`fade ${lang} ${type === 'login' ? 'login-modal' : 'signup-modal'}`} show={modal} onHide={setModal}>
            <Modal.Body>
                {type === 'login' ? <Login setType={setType} setModal={setModal} /> : <Signup setType={setType} setModal={setModal} />}
            </Modal.Body>
    </Modal>
}
export default Authentication