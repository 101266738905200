import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import arrowIcon from "../../assets/icons/arrow-right.svg"
import './style.css'

const CategoryCard = ({category}) => {
  const lang = useSelector(state => state?.lang?.lang)

  return <Link to={`/category/${category.id}`} key={category?.id} style={{textDecoration: 'none'}} className='category-card'>
    <img src={category?.image} alt='category' className='main-img w-100' />
    <div className='information'>
      <h1 className='title'>{lang === 'en' ? category?.name_en : category?.name_ar}</h1>
      <img src={arrowIcon} alt='arrow-icon' className='arrowIcon' />
    </div>
  </Link>
}
export default CategoryCard