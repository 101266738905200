import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../../common/ScrollToTop";
import { useSelector } from "react-redux";
import ProductCard from "../../../Enums/ProductCard";

const Wishlist = ({title}) => {
    const {t} = useTranslation()
    const wishlistProducts = useSelector(state => state?.user?.favorite)

    return <>
        <ScrollToTop />
        <p className="tab-title">{t(title)}</p>
        <Row>
            {!!wishlistProducts?.length && wishlistProducts?.map(product => {
                return <Col md={4}>
                    <ProductCard product={product} hasAddToCart={false} />
                </Col>
            })}
            {!wishlistProducts?.length && <h5 className='pt-5'>{t(`There's no Products`)}</h5>}
        </Row>
    </>
}
export default Wishlist;