import axios from 'axios';
import { API_BASE_URL_ENV } from '../common/common';
import { LogoutFn, setPromoCode, loginConfirmedAction, setCart, setFavorite } from '../store/actions/AuthActions';
import { changeLang } from '../store/actions/LangActions';
import { toast } from 'react-toastify';
import UserService from './UserService';
import { t } from 'i18next';

export function login(data) {
    return axios.post(`${API_BASE_URL_ENV()}/users/login`, data);
}

export function signUp(data) {
    return axios.post(`${API_BASE_URL_ENV()}/users/register`, data);
}


export function getJwt() {
    return JSON.parse(localStorage.getItem("Esen"))?.accessToken;
}

export function saveTokenInLocalStorage(tokenDetails) {
    return localStorage.setItem('Esen', JSON.stringify(tokenDetails));
}

export function Logout(token, dispatch, navigate, pathname) {
    axios.defaults.headers.common["Authorization"] = `${token}`;
    axios.post(`${API_BASE_URL_ENV()}/users/logout`).then(res=>{
        if(res.status === 200){
            dispatch(LogoutFn())
            dispatch(setFavorite([]))
            localStorage.removeItem('EsenCart')
            toast.success(t('Logout Successfully.'))
            if(pathname === '/profile'){
                navigate('/')
            }
        }
    }).catch(()=> dispatch(LogoutFn()))
}

export function checkAutoLogin(dispatch, navigate, pathname) {
    const tokenDetailsString = localStorage.getItem('Esen');
    const masterCartString = localStorage.getItem('EsenCart');
    const promoCode = localStorage.getItem('PromoCodeEsen');
    const lang = localStorage.getItem('Esen_Lang')

    if(!!lang && lang !== 'null'){
        dispatch(changeLang(lang));
    } else {
        dispatch(changeLang('en'));
    }

    if(!!masterCartString){
        dispatch(setCart(JSON.parse(masterCartString)))
    }

    if(!!promoCode && !!masterCartString){
        setTimeout(()=>{
            dispatch(setPromoCode(JSON.parse(promoCode)))
        }, 500)
    }

    if (!tokenDetailsString) {
        dispatch(LogoutFn())
        dispatch(setFavorite([]))
        if(pathname === '/profile'){
            navigate('/')
        }
		return;
    }

    new UserService().profile().then(res=>{
        if(res?.status){
            dispatch(loginConfirmedAction(JSON.parse(tokenDetailsString)));
        }
    }).catch(e=> {
        if(e?.response?.data?.message === "not_authorized_old_Token"){
            dispatch(LogoutFn())
            dispatch(setFavorite([]))
            if(pathname === '/profile'){
                navigate('/')
            }
		    return;
        }
    })
}